// scss dependencies
import '../scss/index.scss';

// app dependencies
import App from './App';

/**
 * @function bootstrap
 * Starts up the main app
 */
function bootstrap() {
    new App();
}

// When the document completes loading, bootstrap the main app
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', bootstrap);
} else {
    bootstrap();
}
