// modules
import ShirtToggle from './modules/ShirtToggle';

/**
 * @class App
 * Main application entry to bootstrap modules
 */
export default class App {
    constructor() {
        const shirtElement = document.querySelector('[data-shirt-toggle]');
        new ShirtToggle(shirtElement);
    }
}
