export default class ShirtToggle {
    constructor(element) {
        this.element = element;
        this.onClick = this.onClick.bind(this);
        this.cacheDOM();
        this.attachEvents();
    }

    cacheDOM() {
        this.toggleButtons = this.element.querySelectorAll('[data-shirt-toggle-button]');
        this.toggleImages = this.element.querySelectorAll('[data-shirt-toggle-image]');
    }

    attachEvents() {
        if (this.toggleButtons) {
            [...this.toggleButtons].forEach((toggleButton) => {
                console.log('toggleButton: ', toggleButton);
                toggleButton.addEventListener('click', this.onClick);
            });
        }
    }

    onClick(event){
        const element = event.target;
        const targetImage = element.dataset.shirtToggleButton;

        console.log('this.toggleImages: ', this.toggleImages);

        [...this.toggleButtons].forEach((button) => {
            if (button === element) {
                button.classList.add('ld-shirts__button--active');
            } else {
                button.classList.remove('ld-shirts__button--active');
            }
        });

        [...this.toggleImages].forEach((image) => {
            if(image.dataset.shirtToggleImage === targetImage) {
                image.classList.add('ld-shirts__image--active');
            } else {
                image.classList.remove('ld-shirts__image--active');
            }
        });
        console.log('targetImage', targetImage);
    }
};